<template>
  <Box>

    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Company Display
        </div>
      </Column>
    </Row>

    <WorkingDisplayRow 
      v-if="this.showDialog == 'workingDialog'" 
      v-on:withEnterPressed="withEnterPressed" 
      v-on:withButtonPressed="withButtonPressed" 
      v-on:withValue="withFieldValue"></WorkingDisplayRow>

    <Row>
      <Column :width="15">
        <Box class="rc-border-thin" :key="redraw + 1">
          <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
            <CellFactory 
              :rowData="row.data"
              :showMissingRequirements="showMissingRequirements"
              v-on:withEnterPressed="withEnterPressed"
              v-on:withValue="withFieldValue"
              v-on:withButtonPressed="withButtonPressed"
                />
          </Row>
        </Box>
      </Column>
    </Row>

    <Row >
      <Column :width="15" >
        <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
          <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
            <CellFactory 
              :rowData="row.data"
              :showMissingRequirements="showMissingRequirements"
              v-on:withEnterPressed="withEnterPressed"
              v-on:withValue="withFieldValue"
              v-on:withButtonPressed="withButtonPressed"
                />
          </Row>
        </Box>
      </Column>
    </Row>

    <Row>
      <Column :width="15">
        <Box class="rc-border-thin" :key="redraw + 2">
          <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
            <CellFactory 
              :rowData="row.data"
              :showMissingRequirements="showMissingRequirements"
              v-on:withEnterPressed="withEnterPressed"
              v-on:withValue="withFieldValue"
              v-on:withButtonPressed="withButtonPressed"
                />
          </Row>
        </Box>
      </Column>
    </Row>

  </Box>
  
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';

import DisplayViewModel from './DisplayViewModel.js';
import DisplayViewUtils from './DisplayViewUtils.js';

export default {
  name: "company-display-view",
  components: {
    Column, Box, Row,
    CellFactory, WorkingDisplayRow,
  },
  props: {
    adminDisplay: { type: Boolean, default: false},
    isAdmin: {type: Boolean, default: false},
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CellFactory: CellFactory,
      
      viewModel: null,
      viewData: [],
      headerData: [],
      footerData: [],

      showMissingRequirements: false,
      selectedId: "",
      showDialog: "",

      DisplayViewModel: DisplayViewModel,
      DisplayViewUtils: DisplayViewUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   'CompanyStorage_lastEvent'
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route (to, from){
      this.model().withToFrom(to, from);
    },
    CompanyStorage_lastEvent() {
      this.model().withCompanyUpdate(this.CompanyStorage_lastEvent);
    }
  },
  mounted: function () {
    this.viewModel = new this.DisplayViewModel(this);
    this.viewModel.withStatePageLoading();
    this.viewModel.clearAndBuildTableData();
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      let companyId = ContentUtils.baseDecode(this.$route.params.operatorId);
      let company = this.domain.companys().findById(companyId);
      if (StringUtils.isNotEmpty(companyId) && (company.isNew() || company.isPointer())) {
        let listPath = ConstUtils.ROUTES.SHARED.LOAD.COMPANY_PATH;
        this.$router.push({
          path: listPath,
          query: {
            companyId: this.$route.params.operatorId,
            page: "company/display"
          } 
        });
      } else {
        this.viewModel.loadItems();
      }
    },

    
    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      var row = this.viewModel.findRowProgress();
      if (row) {
        row.withMessage(msg);
      }
      this.paint();
    },
    
    model: function() {
      if (this.viewModel == null) {
        this.viewModel = new DisplayViewModel(this);
      }
      return this.viewModel;
    },
    
    withEnterPressed: function(valueData) {
      this.model().withEnterPressed(valueData);
    },

    withFieldValue: function(valueData) {
      this.model().withValue(valueData);
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);
    },
    
    reloadPage: function() {
      this.model().reloadPage();
    },

    paint: function() {
      this.redraw++;
    },

    complete: function() {
      setTimeout(this.completeImpl, 200);
    },

    completeImpl: function() {
      const action = this.$route.query["action"]
      const id = this.$route.query["selectedId"];
      if (action == "update") {
        this.selectedId = id;
        this.showDialog = "updateSettingsDialog";
      }

      if (action == "updateCard") {
        this.selectedId = id;
        this.showDialog = "updateCardDialog";
      }

      if (action == "removeCard") {
        this.selectedId = id;
        this.showDialog = "removeCardDialog";
      }
    }
  },
}
</script>