import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";


import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import ButtonDisplayRow from "../../../../../shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "../../../../../shared/cell/spacer/SpacerDisplayRow";

export default class DisplayViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.Contents = ContentUtils;
    this.Strings = new StringUtils();
  }

  start() {
    super.startV2("Company");
  }

  renderHeader() {
  }

  renderFooter() {
  }

  renderBody() {

    let address = this.model().company().address().find();
    let status = new StatusDisplayRow();
    status.withValue("ExpireNext").withObject("This section is incomplete. Without this, inspections will not be valid")
    if (address.isComplete()) {
      status = null;
    }
    this.addBody(new RowDisplayRow().withBorder()
      .withChild(new BoxDisplayRow().withWidth(7).withBorder()
        .withChild(new TitleDisplayRow().withTitle("Commercially Registered Company Details"))
        .withChild(new LabelValueDisplayRow().withLabel("Company Name").withValue(this.model().company().name()))
        .withChild(new LabelValueDisplayRow().withLabel("Phone #").withValue(this.model().company().phone()))
        .withChild(new LabelValueDisplayRow().withLabel("Registration #").withValue(this.model().company().cvorNumber()))
        .withChild(new LabelValueDisplayRow().withLabel("Registration Date").withValue(this.model().company().registrationDateV2().displayAs_YYYY_MM_DD()))
        .withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth(10))
          .withChild(new ButtonDisplayRow().withWidth(5).withName("Upate").withField("Update").withObject("Company"))
        )
      ) 
      .withChild(new BoxDisplayRow().withWidth(8).withBorder()
      .withChild(new TitleDisplayRow().withTitle("Registrated Address"))
        .withChild(status)
        .withChild(new LabelValueDisplayRow().withLabel("No. and Street").withValue(address.street()))
        .withChild(new LabelValueDisplayRow().withLabel("City").withValue(address.city()))
        .withChild(new LabelValueDisplayRow().withLabel("Jurisdiction").withValue(address.province()))
        .withChild(new LabelValueDisplayRow().withLabel("Country").withValue(address.country()))
        .withChild(new LabelValueDisplayRow().withLabel("Postal Code").withValue(address.postal()))
        .withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth(10))
          .withChild(new ButtonDisplayRow().withWidth(5).withName("Upate").withField("Update").withObject("Company.Address"))
        )
      )
      .withChild(new StringDisplayRow().withValue("It is important that this company information matches the information on your commercial motor vehicle registration. If you are operating as a CVOR company, this information must match your CVOR documentation.").withWidth(15).withFontSize("xsmall"))

    );

  }

}