import ContentUtils from "@/utils/ContentUtils";
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import UpdateViewUtils from "./DisplayViewUtils";
import RcResponseEvent from "../../../../../../domain/model/events/RcResponseEvent";

export default class DisplayViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel, true);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  start() {
    super.start();
  }

  withToFrom(to, from) {
    super.withToFrom(to, from);
    return this;
  }

  withValue(valueData) {
    super.withValue(valueData, "company/display");
  }

  withEnterPressed(valueData) {
    super.withEnterPressed(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();

    var context = {}
    context["model"] = this;
    context["query"] = this.panel().$route.query;

    var id = this.company().id();
    
    var event = this.domain().events().company().details(id);
    event.send(this.loadedItemsListener, context);
    return this;
  }

  loadedItemsListener(notification, context) {
    var model = context.model;
    if (!super.loadedItemsListener(notification, context)) {
      return;
    }
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  withCompanyUpdate(eventData) {
    let notification = new RcResponseEvent(this.domain(), eventData);
    if (!notification.isStatusComplete()) {
      return;
    }
  }

}